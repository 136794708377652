define("discourse/plugins/discourse-solved/discourse/initializers/add-topic-list-class", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "add-topic-list-class",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.39.0", api => {
        api.registerValueTransformer("topic-list-item-class", _ref => {
          let {
            value,
            context
          } = _ref;
          if (context.topic.get("has_accepted_answer")) {
            value.push("status-solved");
          }
          return value;
        });
      });
    }
  };
});