define("discourse/plugins/discourse-solved/discourse/components/solved-unaccept-answer-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@ember/template", "discourse/components/d-button", "discourse/helpers/d-icon", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/utilities", "discourse-i18n", "float-kit/components/d-tooltip", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _template, _dButton, _dIcon, _ajax, _ajaxError, _utilities, _discourseI18n, _dTooltip, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.unacceptAnswer = unacceptAnswer;
  function unacceptAnswer(post, appEvents) {
    // TODO (glimmer-post-menu): Remove this exported function and move the code into the button action after the widget code is removed
    unacceptPost(post);
    appEvents.trigger("discourse-solved:solution-toggled", post);
    post.get("topic.postStream.posts").forEach(p => {
      p.set("topic_accepted_answer", false);
      appEvents.trigger("post-stream:refresh", {
        id: p.id
      });
    });
  }
  function unacceptPost(post) {
    if (!post.can_unaccept_answer) {
      return;
    }
    const topic = post.topic;
    post.setProperties({
      can_accept_answer: true,
      can_unaccept_answer: false,
      accepted_answer: false
    });
    topic.set("accepted_answer", undefined);
    (0, _ajax.ajax)("/solution/unaccept", {
      type: "POST",
      data: {
        id: post.id
      }
    }).catch(_ajaxError.popupAjaxError);
  }
  class SolvedUnacceptAnswerButton extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    unacceptAnswer() {
      unacceptAnswer(this.args.post, this.appEvents);
    }
    static #_3 = (() => dt7948.n(this.prototype, "unacceptAnswer", [_object.action]))();
    get solvedBy() {
      if (!this.siteSettings.show_who_marked_solved) {
        return;
      }
      const username = this.args.post.topic.accepted_answer.accepter_username;
      const name = this.args.post.topic.accepted_answer.accepter_name;
      const displayedName = this.siteSettings.display_name_on_posts && name ? name : (0, _utilities.formatUsername)(username);
      if (this.args.post.topic.accepted_answer.accepter_username) {
        return (0, _discourseI18n.i18n)("solved.marked_solved_by", {
          username: displayedName,
          username_lower: username
        });
      }
    }
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <span class="extra-buttons">
          {{#if @post.can_unaccept_answer}}
            {{#if this.solvedBy}}
              <DTooltip @identifier="post-action-menu__solved-accepted-tooltip">
                <:trigger>
                  <DButton
                    class="post-action-menu__solved-accepted accepted fade-out"
                    ...attributes
                    @action={{this.unacceptAnswer}}
                    @icon="square-check"
                    @label="solved.solution"
                    @title="solved.unaccept_answer"
                  />
                </:trigger>
                <:content>
                  {{htmlSafe this.solvedBy}}
                </:content>
              </DTooltip>
            {{else}}
              <DButton
                class="post-action-menu__solved-accepted accepted fade-out"
                ...attributes
                @action={{this.unacceptAnswer}}
                @icon="square-check"
                @label="solved.solution"
                @title="solved.unaccept_answer"
              />
            {{/if}}
          {{else}}
            <span
              class="accepted-text"
              title={{i18n "solved.accepted_description"}}
            >
              <span>{{icon "check"}}</span>
              <span class="accepted-label">
                {{i18n "solved.solution"}}
              </span>
            </span>
          {{/if}}
        </span>
      
    */
    {
      "id": "kZOFQn08",
      "block": "[[[1,\"\\n    \"],[10,1],[14,0,\"extra-buttons\"],[12],[1,\"\\n\"],[41,[30,1,[\"can_unaccept_answer\"]],[[[41,[30,0,[\"solvedBy\"]],[[[1,\"          \"],[8,[32,0],null,[[\"@identifier\"],[\"post-action-menu__solved-accepted-tooltip\"]],[[\"trigger\",\"content\"],[[[[1,\"\\n              \"],[8,[32,1],[[24,0,\"post-action-menu__solved-accepted accepted fade-out\"],[17,2]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"unacceptAnswer\"]],\"square-check\",\"solved.solution\",\"solved.unaccept_answer\"]],null],[1,\"\\n            \"]],[]],[[[1,\"\\n              \"],[1,[28,[32,2],[[30,0,[\"solvedBy\"]]],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[32,1],[[24,0,\"post-action-menu__solved-accepted accepted fade-out\"],[17,2]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"unacceptAnswer\"]],\"square-check\",\"solved.solution\",\"solved.unaccept_answer\"]],null],[1,\"\\n\"]],[]]]],[]],[[[1,\"        \"],[10,1],[14,0,\"accepted-text\"],[15,\"title\",[28,[32,3],[\"solved.accepted_description\"],null]],[12],[1,\"\\n          \"],[10,1],[12],[1,[28,[32,4],[\"check\"],null]],[13],[1,\"\\n          \"],[10,1],[14,0,\"accepted-label\"],[12],[1,\"\\n            \"],[1,[28,[32,3],[\"solved.solution\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@post\",\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-solved/discourse/components/solved-unaccept-answer-button.js",
      "scope": () => [_dTooltip.default, _dButton.default, _template.htmlSafe, _discourseI18n.i18n, _dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SolvedUnacceptAnswerButton;
});